import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import ipad from '../assets/images/ipad.png';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import bgMaster from '../assets/images/starynight.png';
import arrow from '../assets/images/down-arrow.svg';
import team from '../assets/images/team.webp';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about">
              <img src={arrow} alt="scroll down" className="downArrow"  />
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">About Us</h2>
            <p className="text-white-50">
            We are a team of experienced researchers and developers of language modeling technologies. 
            Our focus is on computationally efficient pipelines which bring insight from large unstructured corpora or realtime streams of text.
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid ipad" alt="" />
      </div>
    </section>

{/*     <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={team} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Who we are?</h4>
              <p className="text-black-50 mb-0">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
Nunc nec pellentesque nisl. Sed ultrices mi vitae leo sollicitudin, 
et tincidunt est ornare. Donec mauris ante, dapibus a tempor ac, 
ullamcorper vitae sem. Nam accumsan accumsan posuere. Class aptent 
taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
              </p>
            </div>
          </div>
        </div>


      </div>
    </section> */}


    {/* <SocialLinks /> */}
    <Footer />
  </Layout>
);

export default IndexPage;
