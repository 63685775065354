import React from 'react';

export default function Footer() {
  return (
    <footer id='contact' className="bg-black small text-center text-white-50">
      <div className="container">
        <b>Contact:</b> info@mikolov.com
      </div>
    </footer>
  );
}
